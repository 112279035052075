import React, { useState, useEffect } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  Input,
  FormLabel,
  Spinner,
  Button,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
  Select,
  Textarea,
  Progress,
  useToast,
  Select as ChakraSelect,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { BsPencilSquare } from 'react-icons/bs';
import DeleteBtn from '../components/DeleteBtn';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { BiTrash } from 'react-icons/bi';
import useSWR, { mutate } from 'swr';
import axiosClient, { fetcher } from '../api/api';
import { Formik, Form } from 'formik';
import apiRoute from '../api/route';
import { PENDING } from '../constant/app_constant';

function AdminValidationPage() {
  const { data, error, isLoading } = useSWR(
    `${apiRoute().transaction}/my/validations`,
    fetcher,
    { refreshInterval: 1000 }
  );
  const models = useSWR(`${apiRoute().transaction}/pause`, fetcher);
  const orders_pending = useSWR(`${apiRoute().orders}/${PENDING}`, fetcher, {
    refreshInterval: 10000,
  });

  useEffect(() => {}, [data]);
  return !isLoading ? (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr bg={'white'}>
              <Th p={7}>Nom</Th>
              <Th>Serial Number</Th>
              <Th>Type </Th>
              <Th>Crée le</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data.map(row => (
              <Tr key={row.id} bg={'white'}>
                <Td>{row.product?.name}</Td>
                <Td>
                  {row.product?.meta.find(e => e.meta_key === 'sn').meta_value}
                </Td>
                <Td>{row.product?.product_type?.name}</Td>
                <Td>{row.created_at}</Td>
                <Td>
                  <ConfirmModal item={row}/>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  );
}

const getProgression = (row, validators) => {
  return row.validators !== ''
    ? (row.validators?.split(',').length * 100) / validators.length
    : 0;
};
function ConfirmModal({ children, item }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  useEffect(() => {});
  const validate = () => {
    setIsLoading(true);
    axiosClient
      .post(`${apiRoute().transaction}/set/play`, {
        product_id: item?.product?.id,
        transaction_meta: JSON.stringify(item.transaction_meta),
        validators: item.validators,
      })
      .then(res => {
        setIsLoading(false);
        mutate(`${apiRoute().transaction}/my/validations`);
      });
  };
  return (
    <>
      <Button
        colorScheme="blue"
        mr={3}
        onClick={validate}
        isLoading={isLoading}
      >
        valider
      </Button>
    </>
  );
}
export default AdminValidationPage;
