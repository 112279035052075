import React, { useState, useEffect } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  Input,
  FormLabel,
  Spinner,
  Button,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select as ChakraSelect,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Textarea,
} from '@chakra-ui/react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { BsPencilSquare } from 'react-icons/bs';
import RejectFolder from '../components/RejectFolder';
import TpModal from '../components/TpModal';
import * as Yup from 'yup';
import useSWR, { mutate } from 'swr';
import axiosClient, { fetcher } from '../api/api';
import { Formik, Form } from 'formik';
import apiRoute from '../api/route';
import { AiOutlineEye } from 'react-icons/ai';
import { VStack } from '@chakra-ui/react';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import envConfig from '../config/env';
import {
  AppFunctionConstants,
  CANCELLED,
  DONE,
  FOLDER_REJECTED,
  FOLDER_STARTED,
  FOLDER_VALIDATE,
  ONGOING,
  PENDING,
} from '../constant/app_constant';
import { getAction } from '../utils/helpers';
import { useSelector } from 'react-redux';
function AdminOrdersPage() {
  const { data, error, isLoading, mutate } = useSWR(
    `${apiRoute().orders}/${PENDING}`,
    fetcher
  );
  const user = useSelector(state => state.user.user);
  const orders_rejected = useSWR(`${apiRoute().orders}/${CANCELLED}`, fetcher);
  const orders_validated = useSWR(`${apiRoute().orders}/${DONE}`, fetcher);
  const [isLikeLoading, setIsLikeLoading] = useState(false);

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>En Attente </Tab>
          <Tab>Valider</Tab>
          <Tab>Rejeté</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {isLoading ? (
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                minH={'70vh'}
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Flex>
            ) : (
              <>
                <Box mb={4}></Box>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr bg={'white'}>
                        <Th p={7}>Nom et Prenoms</Th>
                        <Th p={7}>Email</Th>
                        <Th p={7}>Télephone</Th>
                        <Th p={7}>Nombre de Tpe</Th>
                        <Th p={7}>Quittance de Payement</Th>
                        <Th p={7}>Prix Unitaire</Th>
                        {getAction(user)?.find(
                          el => el.value === AppFunctionConstants.DELETE
                        ) &&
                          getAction(user)?.find(
                            el => el.value === AppFunctionConstants.UPDATE
                          ) && <Th p={7}>Action</Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.map(row => (
                        <Tr key={row.id} bg={'white'}>
                          <Td>
                            {row.user.first_name} {row.user.last_name}
                          </Td>
                          <Td>{row.user.email}</Td>
                          <Td>{row.user.first_name}</Td>
                          <Td>{row.size}</Td>
                          <Td textAlign={'center'}>
                            <PreviewPdfModal url={row.file_url}>
                              <AiOutlineEye onClick={() => {}} />
                            </PreviewPdfModal>
                          </Td>
                          <Td>{getPrice(row.order_meta)}</Td>

                          {getAction(user)?.find(
                            el => el.value === AppFunctionConstants.DELETE
                          ) &&
                            getAction(user)?.find(
                              el => el.value === AppFunctionConstants.UPDATE
                            ) && (
                              <Td>
                                <HStack>
                                  <RejectOrder order_id={row.id}>
                                    <IconButton
                                      colorScheme="red"
                                      aria-label="Call Segun"
                                      size="lg"
                                      icon={<AiFillDislike />}
                                    />
                                  </RejectOrder>

                                  <ConfirmModal
                                    order_id={row.id}
                                    url={`${apiRoute().orders_terminate}`}
                                    user_id={row.user_id}
                                  >
                                    <IconButton
                                      colorScheme="blue"
                                      aria-label="Call Segun"
                                      size="lg"
                                      icon={<AiFillLike />}
                                      isLoading={isLikeLoading}
                                    />
                                  </ConfirmModal>
                                </HStack>
                              </Td>
                            )}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <TabPanel>
              {orders_validated?.isLoading ? (
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  minH={'70vh'}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Flex>
              ) : (
                <>
                  <Box mb={4}></Box>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr bg={'white'}>
                          <Th p={7}>Nom et Prenoms</Th>
                          <Th p={7}>Email</Th>
                          <Th p={7}>Télephone</Th>
                          <Th p={7}>Nombre de Tpe</Th>
                          <Th p={7}>Quittance de Payement</Th>
                          <Th p={7}>Prix Unitaire</Th>
                          <Th p={7}>Part ELG</Th>
                          <Th p={7}>Part Personnel</Th>
                          <Th p={7}>Part Apporteur d'affaire</Th>
                          <Th p={7}>Total</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {orders_validated?.data?.data.map(row => (
                          <Tr key={row.id} bg={'white'}>
                            <Td>
                              {row.user.first_name} {row.user.last_name}
                            </Td>
                            <Td>{row.user.email}</Td>
                            <Td>{row.user.first_name}</Td>
                            <Td>{row.size}</Td>
                            <Td textAlign={'center'}>
                              <PreviewPdfModal url={row.file_url}>
                                <AiOutlineEye onClick={() => {}} />
                              </PreviewPdfModal>
                            </Td>
                            <Td>{row.order_meta.tpe}</Td>
                            <Td>{row.order_meta.company}</Td>
                            <Td>{row.order_meta.staff}</Td>
                            <Td>{row.order_meta.intermediary}</Td>
                            <Td>{getPrice(row.order_meta) * row.size} </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </TabPanel>
          </TabPanel>
          <TabPanel>
            <TabPanel>
              {orders_rejected?.isLoading ? (
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  minH={'70vh'}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Flex>
              ) : (
                <>
                  <Box mb={4}></Box>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr bg={'white'}>
                          <Th p={7}>Nom et Prenoms</Th>
                          <Th p={7}>Email</Th>
                          <Th p={7}>Télephone</Th>
                          <Th p={7}>Nombre de Tpe</Th>
                          <Th p={7}>Quittance de Payement</Th>
                          <Th p={7}>Prix Unitaire</Th>
                          <Th p={7}>Motif</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {orders_rejected?.data?.data.map(row => (
                          <Tr key={row.id} bg={'white'}>
                            <Td>
                              {row.user.first_name} {row.user.last_name}
                            </Td>
                            <Td>{row.user.email}</Td>
                            <Td>{row.user.first_name}</Td>
                            <Td>{row.size}</Td>
                            <Td textAlign={'center'}>
                              <PreviewPdfModal url={row.file_url}>
                                <AiOutlineEye onClick={() => {}} />
                              </PreviewPdfModal>
                            </Td>
                            <Td>{getPrice(row.order_meta)}</Td>
                            <Td>{row.rejection_object}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </TabPanel>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

function getMetaFiled(meta, key) {
  let field = meta.find(e => e.meta_key === key);
  if (field) {
    return field.meta_value != null ? field.meta_value : '';
  }
  return '';
}
function getPrice(meta) {
  let sum =
    parseInt(meta.tpe) +
    parseInt(meta.staff) +
    parseInt(meta.intermediary) +
    parseInt(meta.company);
  return sum;
}

function PreviewPdfModal({ children, url }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <iframe
                src={`${envConfig().apiBaseUrl}/storage/${url}`}
                width="100%"
                height="500px"
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function ConfirmModal({ children, title, order_id, url, user_id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const validateSchema = Yup.object().shape({
    name: Yup.string().required('Ce champs est requis'),
  });
  const out = useSWR(`${apiRoute().transaction}/stop/${user_id}`, fetcher);

  const toast = useToast();
  useEffect(() => {});
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          name: '',
        }}
        onSubmit={(values, { resetForm }) => {
          if (
            out?.data?.data != null &&
            out?.data?.data?.filter(e => e.product != null).length === 0
          ) {
            toast({
              title: 'Validations de Commande de TPE',
              description: "Aucun TPE n'est assicié à cet uitilisateur.",
              status: 'warning',
              duration: 1000,
              isClosable: true,
              position: 'top ',
            });
            return;
          }
          setIsLoading(true);
          axiosClient
            .post(url, {
              order_id: order_id,
            })
            .then(res => {
              setIsLoading(false);
              //   mutate(`${apiRoute().orders}/${CANCELLED}`);
              mutate(`${apiRoute().orders}/${PENDING}`);
              mutate(`${apiRoute().orders}/${DONE}`);
              onClose();
              toast({
                title: 'Validations de Commande de TPE',
                description: 'Operation effectué avec succès.',
                status: 'success',
                duration: 1000,
                isClosable: true,
                position: 'top ',
              });
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {out.isLoading ? (
                    <Flex alignItems={'center'} justifyContent={'center'}>
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                    </Flex>
                  ) : (
                    <>
                      <FormControl
                        isRequired
                        isInvalid={errors.name && touched.name}
                      >
                        <FormLabel>Livreur</FormLabel>
                        <Input
                          placeholder=""
                          isInvalid={errors.name && touched.name}
                          value={values.name}
                          onChange={handleChange('name')}
                        />
                        {errors.name && touched.name && (
                          <FormErrorMessage>{errors.name}</FormErrorMessage>
                        )}
                      </FormControl>
                      <Box>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th p={7}>Nom</Th>
                              <Th>Serial Number</Th>
                              <Th>Type </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {out?.data?.data
                              ?.filter(e => e.product != null)
                              .map(row => (
                                <Tr key={row.id} bg={'white'}>
                                  <Td>{row.product?.name}</Td>
                                  <Td>
                                    {
                                      row.product?.meta.find(
                                        e => e.meta_key === 'sn'
                                      ).meta_value
                                    }
                                  </Td>
                                  <Td>{row.product?.product_type?.name}</Td>
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      </Box>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}
function RejectOrder({ children, onAction, order_id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const cancelRef = React.useRef();
  let [value, setValue] = React.useState('');
  let handleInputChange = e => {
    let inputValue = e.target.value;
    setValue(inputValue);
  };

  const handleReject = () => {
    if (value === '') {
      alert('Remplir motif de rejet');
      return;
    }
    setIsLoading(true);
    axiosClient
      .post(apiRoute().reject_order, {
        order_id: order_id,
        object: value,
      })
      .then(res => {
        setIsLoading(false);
        mutate(`${apiRoute().orders}/${PENDING}`);
        mutate(`${apiRoute().orders}/${CANCELLED}`);
      });
  };
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Motifs de Rejet</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Textarea
              value={value}
              onChange={handleInputChange}
              placeholder=""
              size="sm"
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={handleReject}
              isLoading={isLoading}
            >
              Valider
            </Button>
            <Button ml={3} ref={cancelRef} onClick={onClose}>
              Quitter
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
export default AdminOrdersPage;
