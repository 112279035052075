import React, { useState, useEffect } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  Input,
  FormLabel,
  Spinner,
  Button,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { BsPencilSquare } from 'react-icons/bs';
import RejectFolder from '../components/RejectFolder';
import TpModal from '../components/TpModal';
import * as Yup from 'yup';
import useSWR, { mutate } from 'swr';
import axiosClient, { fetcher } from '../api/api';
import { Formik, Form } from 'formik';
import apiRoute from '../api/route';
import { AiOutlineEye } from 'react-icons/ai';
import { VStack } from '@chakra-ui/react';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import envConfig from '../config/env';
import {
  FOLDER_REJECTED,
  FOLDER_STARTED,
  FOLDER_VALIDATE,
} from '../constant/app_constant';
function AdminUserFolder() {
  const { data, error, isLoading, mutate } = useSWR(
    `${apiRoute().pendind_folder}/${FOLDER_STARTED}`,
    fetcher
  );
  const folders_rejected = useSWR(
    `${apiRoute().pendind_folder}/${FOLDER_REJECTED}`,
    fetcher
  );
  const folders_validated = useSWR(
    `${apiRoute().pendind_folder}/${FOLDER_VALIDATE}`,
    fetcher
  );
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const handleValidateFolder = user_id => {
    setIsLikeLoading(true);
    axiosClient
      .post(apiRoute().validate_folder, {
        user_id: user_id,
      })
      .then(res => {
        setIsLikeLoading(false);
        mutate(`${apiRoute().pendind_folder}/${FOLDER_STARTED}`);
        mutate(`${apiRoute().pendind_folder}/${FOLDER_VALIDATE}`);
      });
  };
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>En Attente </Tab>
          <Tab>Valider</Tab>
          <Tab>Rejeté</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {isLoading ? (
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                minH={'70vh'}
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Flex>
            ) : (
              <>
                <Box mb={4}></Box>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr bg={'white'}>
                        <Th p={7}>Nom et Prenoms</Th>
                        <Th p={7}>Email</Th>
                        <Th p={7}>Télephone</Th>
                        <Th p={7}>Entreprise</Th>
                        <Th p={7}>Ifu</Th>
                        <Th p={7}>Rccm</Th>
                        <Th p={7}>Cip</Th>
                        <Th p={7}>Cni</Th>
                        <Th p={7}>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.map(row => (
                        <Tr key={row.id} bg={'white'}>
                          <Td>
                            {row.first_name} {row.last_name}
                          </Td>
                          <Td>{row.email}</Td>
                          <Td>{getMetaFiled(row.user_meta, 'bus_phone')}</Td>
                          <Td>{getMetaFiled(row.user_meta, 'name')}</Td>
                          <Td>
                            <Box>
                              {getMetaFiled(row.user_meta, 'ifu')}

                              <Box mt="2">
                                <PreviewPdfModal
                                  url={getMetaFiled(row.user_meta, 'ifu_url')}
                                >
                                  <AiOutlineEye
                                    onClick={() => {
                                      
                                    }}
                                  />
                                </PreviewPdfModal>
                              </Box>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              {getMetaFiled(row.user_meta, 'rccm')}
                              <Box mt="2">
                                <PreviewPdfModal
                                  url={getMetaFiled(row.user_meta, 'rccm_url')}
                                >
                                  <AiOutlineEye
                                    onClick={() => {
                                      
                                    }}
                                  />
                                </PreviewPdfModal>
                              </Box>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              {getMetaFiled(row.user_meta, 'cip')}
                              <Box mt="2">
                                <PreviewPdfModal
                                  url={getMetaFiled(row.user_meta, 'cip_url')}
                                >
                                  <AiOutlineEye
                                    onClick={() => {
                                      
                                    }}
                                  />
                                </PreviewPdfModal>
                              </Box>
                            </Box>
                          </Td>
                          <Td>
                            <Box>
                              {getMetaFiled(row.user_meta, 'cni')}
                              <Box mt="2">
                                <PreviewPdfModal
                                  url={getMetaFiled(row.user_meta, 'cni_url')}
                                >
                                  <AiOutlineEye
                                    onClick={() => {
                                      
                                    }}
                                  />
                                </PreviewPdfModal>
                              </Box>
                            </Box>
                          </Td>
                          <Td>
                            <HStack>
                              <RejectFolder
                                user_id={row.id}
                                onAction={() => {
                                
                                }}
                              >
                                <IconButton
                                  colorScheme="red"
                                  aria-label="Call Segun"
                                  size="lg"
                                  icon={<AiFillDislike />}
                                />
                              </RejectFolder>

                              <IconButton
                                colorScheme="blue"
                                aria-label="Call Segun"
                                size="lg"
                                icon={<AiFillLike />}
                                isLoading={isLikeLoading}
                                onClick={()=>handleValidateFolder(row.id)}
                              />
                            </HStack>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <TabPanel>
              {folders_validated?.isLoading ? (
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  minH={'70vh'}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Flex>
              ) : (
                <>
                  <Box mb={4}></Box>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr bg={'white'}>
                          <Th p={7}>Nom et Prenoms</Th>
                          <Th p={7}>Email</Th>
                          <Th p={7}>Télephone</Th>
                          <Th p={7}>Entreprise</Th>
                          <Th p={7}>Ifu</Th>
                          <Th p={7}>Rccm</Th>
                          <Th p={7}>Cip</Th>
                          <Th p={7}>Cni</Th>
                         
                        </Tr>
                      </Thead>
                      <Tbody>
                        {folders_validated?.data?.data.map(row => (
                          <Tr key={row.id} bg={'white'}>
                            <Td>
                              {row.first_name} {row.last_name}
                            </Td>
                            <Td>{row.email}</Td>
                            <Td>{getMetaFiled(row.user_meta, 'bus_phone')}</Td>
                            <Td>{getMetaFiled(row.user_meta, 'name')}</Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'ifu')}

                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(row.user_meta, 'ifu_url')}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'rccm')}
                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(
                                      row.user_meta,
                                      'rccm_url'
                                    )}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'cip')}
                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(row.user_meta, 'cip_url')}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'cni')}
                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(row.user_meta, 'cni_url')}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </TabPanel>
          </TabPanel>
          <TabPanel>
            <TabPanel>
              {folders_rejected?.isLoading ? (
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  minH={'70vh'}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Flex>
              ) : (
                <>
                  <Box mb={4}></Box>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr bg={'white'}>
                          <Th p={7}>Nom et Prenoms</Th>
                          <Th p={7}>Email</Th>
                          <Th p={7}>Télephone</Th>
                          <Th p={7}>Entreprise</Th>
                          <Th p={7}>Ifu</Th>
                          <Th p={7}>Rccm</Th>
                          <Th p={7}>Cip</Th>
                          <Th p={7}>Cni</Th>
                          <Th p={7}>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {folders_rejected?.data?.data.map(row => (
                          <Tr key={row.id} bg={'white'}>
                            <Td>
                              {row.first_name} {row.last_name}
                            </Td>
                            <Td>{row.email}</Td>
                            <Td>{getMetaFiled(row.user_meta, 'bus_phone')}</Td>
                            <Td>{getMetaFiled(row.user_meta, 'name')}</Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'ifu')}

                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(row.user_meta, 'ifu_url')}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'rccm')}
                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(
                                      row.user_meta,
                                      'rccm_url'
                                    )}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'cip')}
                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(row.user_meta, 'cip_url')}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Box>
                                {getMetaFiled(row.user_meta, 'cni')}
                                <Box mt="2">
                                  <PreviewPdfModal
                                    url={getMetaFiled(row.user_meta, 'cni_url')}
                                  >
                                    <AiOutlineEye
                                      onClick={() => {
                                        
                                      }}
                                    />
                                  </PreviewPdfModal>
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <HStack>
                                {/* <RejectFolder
                                  onAction={() => {
                                 
                                  }}
                                > */}
                                {/* <IconButton
                                    colorScheme="red"
                                    aria-label="Call Segun"
                                    size="lg"
                                    icon={<AiFillDislike />}
                                  /> */}
                                {/* </RejectFolder> */}

                                <IconButton
                                  colorScheme="blue"
                                  aria-label="Call Segun"
                                  size="lg"
                                  icon={<AiFillLike />}
                                />
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </TabPanel>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

function getMetaFiled(meta, key) {
  let field = meta.find(e => e.meta_key === key);
  if (field) {
    return field.meta_value != null ? field.meta_value : '';
  }
  return '';
}
function PreviewPdfModal({ children, url }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <iframe
                src={`${envConfig().apiBaseUrl}/storage/${url}`}
                width="100%"
                height="500px"
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AdminUserFolder;
