import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import AdminLayout from '../layouts/AdminLayout';
import DefaultLayout from '../layouts/DefaultLayout';
import AuthLayout from '../layouts/AuthLayout';
import AdminPermission from '../pages/AdminPermission';
import AdminRole from '../pages/AdminRole';
import PrivateRoute from './PrivateRoute';
import SettingsPage from '../pages/SettingsPage';
import Login from '../pages/Login';
import Register from '../pages/Register';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import AdminRoute from '../pages/AdminRoute';
import Contact from '../pages/Contact';
import HomePage from '../pages/HomePage';
import ProfilePage from '../pages/ProfilePage';
import AdminPage from '../pages/AdminPage';
import AuthGuardRoute from './AuthGuardRoute';
import AdminManageUser from '../pages/AdminManageUser';
import AdminProductType from '../pages/AdminProductType';
import AdminTpe from '../pages/AdminTpe';
import AdminTpeOut from '../pages/AdminTpeOut';
import AdminUserFolder from '../pages/AdminUserFolder';
import AdminOrdersPage from '../pages/AdminOrdersPage';
import AdminHomePage from '../pages/AdminHomePage';
import NotFoundPage from '../pages/NotFoundPage';
import AdminDocumentPage from '../pages/AdminDocumentsPage';
import AdminValidationPage from '../pages/AdminValidatorPage';


function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AuthLayout />} >
                    <Route path="/login" element={<AuthGuardRoute element={<Login/>}/>}  />
                    <Route path="/register" element={<AuthGuardRoute element={<Register/>}/>} />
                    <Route path="/forgot-password" element={<AuthGuardRoute element={<ForgotPassword/>}/>} />
                    <Route path="/reset-password" element={<AuthGuardRoute element={<ResetPassword/>}/>} />
                  
                </Route>
                <Route element={<DefaultLayout />} >
                    <Route path="/" element={<HomePage/>}  />
                    <Route path="/contacts/" element={<Contact/>} />
                </Route>
                <Route element={<PrivateRoute element={<AdminLayout/>}/>} >
                    <Route path="/admin" element={<PrivateRoute element={<AdminHomePage/>}/>} />
                    <Route path="/admin/users/*" element={<div>Hello admin Home</div>} />
                    <Route path="/admin/permissions/*" element={<PrivateRoute element={<AdminPermission/>}/>} />
                    <Route path="/admin/settings/*" element={<PrivateRoute element={<SettingsPage/>}/>} />
                    <Route path="/admin/roles/*" element={<PrivateRoute element={<AdminRole/>}/>} />
                    <Route path="/admin/manage/users" element={<PrivateRoute element={<AdminManageUser/>}/>} />
                    <Route path="/admin/routes/*" element={<PrivateRoute element={<AdminRoute/>}/>} />
                    <Route path="/admin/profile/*" element={<PrivateRoute element={<ProfilePage/>}/>} />
                    <Route path="/admin/produtct_types/*" element={<PrivateRoute element={<AdminProductType/>}/>} />
                    <Route path="/admin/tpe/*" element={<PrivateRoute element={<AdminTpe/>}/>} />
                    <Route path="/admin/tpe/request/out" element={<PrivateRoute element={<AdminTpeOut/>}/>} />
                    <Route path="/admin/tpe/validation" element={<PrivateRoute element={<AdminValidationPage/>}/>} />
                    <Route path="/admin/folder/managment" element={<PrivateRoute element={<AdminUserFolder/>}/>} />
                    <Route path="/admin/tpe/orders" element={<PrivateRoute element={<AdminOrdersPage/>}/>} />
                    <Route path="/admin/tpe/documents" element={<PrivateRoute element={<AdminDocumentPage/>}/>} />
                    <Route path="/not/found" element={<NotFoundPage/>} />
                    <Route path="*" element={<NotFoundPage/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;