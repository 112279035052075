import React, { useState, useEffect } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  Input,
  FormLabel,
  Spinner,
  Button,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
  Textarea,
  Progress,
  useToast,
  Select as ChakraSelect,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { BsPencilSquare } from 'react-icons/bs';
import DeleteBtn from '../components/DeleteBtn';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { BiTrash } from 'react-icons/bi';
import useSWR, { mutate } from 'swr';
import axiosClient, { fetcher } from '../api/api';
import { Formik, Form } from 'formik';
import apiRoute from '../api/route';
import { PENDING } from '../constant/app_constant';
import { BsChevronRight } from 'react-icons/bs';
import Select from 'react-select';

function AdminTpeOut() {
  const { data, error, isLoading, mutate } = useSWR(
    `${apiRoute().transaction}/mouve`,
    fetcher
  );
  const models = useSWR(`${apiRoute().transaction}/pause`, fetcher);
  const orders_pending = useSWR(`${apiRoute().orders_unhydrate}/${PENDING}`, fetcher, {
    refreshInterval: 10000,
  });

  return !isLoading && !models.isLoading && !orders_pending.isLoading ? (
    <>
      <Tabs>
        <TabList>
          <Tab>Lancer Sortie </Tab>
          <Tab>Assigner TPE aux Clients</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box mb={4}>
              <LinkModal
                url={`${apiRoute().transaction}/set/mouve`}
                models={models.data.data}
              >
                <Button
                  rightIcon={<MdAdd />}
                  colorScheme="teal"
                  variant="solid"
                >
                  Nouveau
                </Button>
              </LinkModal>
            </Box>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr bg={'white'}>
                    <Th p={7}>Nom</Th>
                    <Th>Serial Number</Th>
                    <Th>Type </Th>
                    <Th>Progression de Validation</Th>
                    <Th>Crée le</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.data.map(row => (
                    <Tr key={row.id} bg={'white'}>
                      <Td>{row.product?.name}</Td>
                      <Td>
                        {
                          row.product?.meta.find(e => e.meta_key === 'sn')
                            .meta_value
                        }
                      </Td>
                      <Td>{row.product?.product_type?.name}</Td>
                      <Td>
                        <Progress
                          colorScheme="green"
                          size="sm"
                          value={getProgression(row, data.validators)}
                        />
                      </Td>
                      <Td>{row.created_at}</Td>
                      <Td>
                        {getProgression(row, data.validators) === 100
                          ? // <ConfirmModal
                            //   orders={orders_pending.data.data}
                            //   transaction_id={row.id}
                            //   product_id={row.product.id}
                            //   url={`${apiRoute().move_to_play}`}
                            // >
                            //   <Button colorScheme="blue" mr={3} >
                            //     valider
                            //   </Button>
                            // </ConfirmModal>
                            "Vous pouvez assigner le TPE al'utilisateur"
                          : 'Validation en anttente'}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            {orders_pending.isLoading ? (
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                minH={'70vh'}
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Flex>
            ) : (
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr bg={'white'}>
                      <Th p={7}>Nom et Prenoms</Th>
                      <Th p={7}>Email</Th>
                      <Th p={7}>Télephone</Th>
                      <Th p={7}>Nombre de Tpe</Th>
                      <Th p={7}>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {orders_pending.data?.data?.map(row => (
                      <Tr key={row.id} bg={'white'}>
                        <Td>
                          {row.user.first_name} {row.user.last_name}
                        </Td>
                        <Td>{row.user.email}</Td>
                        <Td>{row.user.first_name}</Td>
                        <Td>{row.size}</Td>

                        <Td>
                          <LinkTpeModal
                            validators={row.user.id}
                            size={row.size}
                            url={`${apiRoute().move_to_play}`}
                            order_id={row.id}
                            models={data?.data?.filter(
                              row =>
                                getProgression(row, data?.validators) === 100
                            )}
                          >
                            <IconButton
                              colorScheme="blue"
                              aria-label="Call Segun"
                              size="lg"
                              icon={<BsChevronRight />}
                            />
                          </LinkTpeModal>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  ) : (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  );
}

function LinkModal({ children, title, sn = '', models, url }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const validateSchema = Yup.object().shape({
    sn: Yup.string().required('Ce champs est requis'),
  });

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          sn: sn,
        }}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          axiosClient
            .post(url, {
              product_id: values.sn,
            })
            .then(res => {
              setIsLoading(false);
              mutate(`${apiRoute().transaction}/mouve`);
              mutate(`${apiRoute().transaction}/pause`);
              if (sn === '') {
                resetForm({
                  values: {
                    sn: '',
                  },
                });
              }

              onClose();
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl isRequired isInvalid={errors.sn && touched.sn}>
                    <FormLabel>SN</FormLabel>

                    <ChakraSelect
                      placeholder="Serial number"
                      isInvalid={errors.sn && touched.sn}
                      value={values.sn}
                      onChange={handleChange('sn')}
                    >
                      {models.map(e => (
                        <option value={e.product?.id}>{e.product?.meta.find(e => e.meta_key === 'sn').meta_value}</option>
                      ))}
                    </ChakraSelect>
                    {errors.sn && touched.sn && (
                      <FormErrorMessage>{errors.sn}</FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Fermer
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}
function LinkTpeModal({ children, title, models, url, size, validators,order_id}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentData, setCurrenData] = useState([]);

  const validateSchema = Yup.object().shape({
    options: Yup.mixed()
      .required('Ce champs est requis')
      .test('fileSize', `Veillez selectioner ${size} TPE`, value => {
        // if (!value) return true;
        return value && value.length === size; // 5MB
      }),
  });
  useEffect(() => {
    setData(
      models.map(el => ({
        label: el.product.meta.find(e => e.meta_key === 'sn').meta_value,
        value: el.id,
        ...el,
      }))
    );
  }, [models]);
  const toast = useToast();
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          options: [],
        }}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          axiosClient
            .post(url, {
              ...values,
              validators: validators,
              order_id:order_id
            })
            .then(res => {
              setIsLoading(false);
              mutate(`${apiRoute().transaction}/mouve`);
              mutate(`${apiRoute().transaction}/pause`);
              mutate(`${apiRoute().orders_unhydrate}/${PENDING}`);
              onClose();
              toast({
                title: 'Assignations',
                description: 'Operation effectué avec succès.',
                status: 'success',
                duration: 1000,
                isClosable: true,
                position: 'top ',
              });
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
          resetForm,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl
                    isRequired
                    isInvalid={errors.options && touched.options}
                  >
                    <FormLabel>TPE</FormLabel>

                    <Select
                      isMulti
                      placeholder=""
                      isInvalid={errors.options && touched.options}
                      onChange={option => {
                        // setSelectedOption(option);
                        setCurrenData(option);
                        setFieldValue('options', option, true);
                      }}
                      // defaultValue={data}
                      options={data}
                    ></Select>
                    {errors.options && touched.options && (
                      <FormErrorMessage>{errors.options}</FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Fermer
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}
const getProgression = (row, validators) => {
  return row.validators !== ''
    ? (row.validators?.split(',').length * 100) / validators.length
    : 0;
};
function ConfirmModal({
  children,
  title,
  orders,
  product_id,
  transaction_id,
  url,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const validateSchema = Yup.object().shape({
    user_id: Yup.number().required('Ce champs est requis'),
  });
  const toast = useToast();
  useEffect(() => {});
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          user_id: '',
        }}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          axiosClient
            .post(url, {
              product_id: product_id,
              transaction_id: transaction_id,
              user_id: values.user_id,
              transaction_meta: JSON.stringify([
                { meta_key: 'user_id', meta_value: values.user_id },
              ]),
            })
            .then(res => {
              setIsLoading(false);
              mutate(`${apiRoute().transaction}/mouve`);
              mutate(`${apiRoute().transaction}/pause`);
              mutate(`${apiRoute().orders}/${PENDING}`);
              onClose();
              toast({
                title: 'Sortir de TPE',
                description: 'Operation effectué avec succès.',
                status: 'success',
                duration: 1000,
                isClosable: true,
                position: 'top ',
              });
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl
                    isRequired
                    isInvalid={errors.user_id && touched.user_id}
                  >
                    <FormLabel>Utilisateur</FormLabel>
                    <ChakraSelect
                      placeholder=""
                      isInvalid={errors.user_id && touched.user_id}
                      value={values.user_id}
                      onChange={handleChange('user_id')}
                    >
                      {orders.map(row => (
                        <option value={row.user.id}>
                          {row.user.first_name} {row.user.last_name}
                        </option>
                      ))}
                    </ChakraSelect>
                    {errors.user_id && touched.user_id && (
                      <FormErrorMessage>{errors.user_id}</FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default AdminTpeOut;
