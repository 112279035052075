import React, { useState, useEffect } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  Input,
  FormLabel,
  Spinner,
  Button,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { BsPencilSquare } from 'react-icons/bs';
import DeleteBtn from '../components/DeleteBtn';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { BiTrash } from 'react-icons/bi';
import useSWR, { mutate } from 'swr';
import axiosClient, { fetcher } from '../api/api';
import { Formik, Form } from 'formik';
import apiRoute from '../api/route';
import Pagination from '../components/Pagination';
import axios from 'axios';
import { useSelector } from 'react-redux';
import envConfig from '../config/env';
import { AiOutlineEye } from 'react-icons/ai';
function AdminDocumentPage() {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isLoading, mutate } = useSWR(
    `${apiRoute().documents_paginate}?per_page=${perPage}&page=${currentPage}`,
    fetcher
  );
  return !isLoading ? (
    <>
      <Box mb={4}>
       
        <LinkModal
          url={apiRoute().documents}
          currentPage={currentPage}
          perPage={perPage}
        >
          <Button rightIcon={<MdAdd />} colorScheme="teal" variant="solid">
            Nouveau
          </Button>
        </LinkModal>
      </Box>
      <TableContainer>
        <Table>
          <Thead>
            <Tr bg={'white'}>
              <Th p={7}>Receveur</Th>
              <Th>Reference</Th>
              <Th>Quantité</Th>
              <Th>Fiche</Th>
              <Th>Crée le</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data.data.map(row => (
              <Tr key={row.id} bg={'white'}>
                <Td>{row.receiver}</Td>
                <Td>{row.reference}</Td>
                <Td>{row.quantities}</Td>
                <Td>
                  <Box mt="2">
                    <PreviewPdfModal
                      url={row.file_url}
                    >
                      <AiOutlineEye onClick={() => {}} />
                    </PreviewPdfModal>
                  </Box>
                </Td>
                <Td>{row.created_at}</Td>
                <Td>
                  <HStack spacing="lg" gap={3}>
                    <DeleteBtn
                      onAction={setIsLoading => {
                        setIsLoading(true);
                        axiosClient
                          .delete(`${apiRoute().documents}/${row.id}`)
                          .then(res => {
                            mutate();
                            setIsLoading(false);
                          });
                      }}
                    >
                      <IconButton
                        colorScheme="red"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<BiTrash />}
                      />
                    </DeleteBtn>
                    <LinkModal
                      url={`${apiRoute().documents}/${row.id}`}
                      title={'Modifier'}
                      receiver={row.receiver}
                      reference={row.reference}
                      quantities={row.quantities}
                      file_url={row.file_url}
                      currentPage={currentPage}
                      perPage={perPage}
                    >
                      <IconButton
                        colorScheme="blue"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<BsPencilSquare />}
                      />
                    </LinkModal>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {data.data?.total > perPage && (
        <Pagination
          currentPage={data.data?.current_page}
          totalPages={data.data?.last_page}
          onPageChange={page => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  ) : (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  );
}

function LinkModal({
  children,
  title,
  receiver = '',
  reference = '',
  quantities = '',
  file_url = '',
  url,
  currentPage,
  perPage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user.user);
  const validateSchema = Yup.object().shape({
    receiver: Yup.string().required('Ce champs est requis'),
    reference: Yup.string().required('Ce champs est requis'),
    file: Yup.mixed()
      .required('Ce champs est requis')
      .test('fileSize', "La taille l' est trop grande ", value => {
        if (!value) return true;
        return value && value.size <= 2 * 1024 * 1024; // 5MB
      }),
    quantities: Yup.number().required('Ce champs est requis'),
  });
  const handleFileChange = (e, setFieldValue) => {
    setFieldValue('file', e.target.files[0]);
    console.log(e.target.files[0]);
  };
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          receiver: receiver,
          reference: reference,
          quantities: quantities,
          file:
            file_url !== ''
              ? {
                  size: 1024 * 2,
                  name: 'default',
                  type: 'application/pdf',
                  webkitRelativePath: '',
                }
              : null,
        }}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          let formData = new FormData();
          formData.append('receiver', values.receiver);
          formData.append('reference', values.reference);
          formData.append('quantities', values.quantities);
          if (values.file.name !== 'default') {
            formData.append('file', values.file);
          }

          axios
            .post(`${envConfig().apiBaseUrl}${url}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + user.token,
              },
            })
            .then(res => {
              mutate(
                `${
                  apiRoute().documents_paginate
                }?per_page=${perPage}&page=${currentPage}`
              );
              setIsLoading(false);
              resetForm();
              onClose();
            })
            .catch(err => {
              setIsLoading(false);
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
          resetForm,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl
                    isRequired
                    isInvalid={errors.receiver && touched.receiver}
                  >
                    <FormLabel>Receveur</FormLabel>
                    <Input
                      placeholder="Nom du receiveur"
                      isInvalid={errors.receiver && touched.receiver}
                      value={values.receiver}
                      onChange={handleChange('receiver')}
                    />
                    {errors.receiver && touched.receiver && (
                      <FormErrorMessage>{errors.receiver}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.reference && touched.reference}
                  >
                    <FormLabel>Reference </FormLabel>
                    <Input
                      placeholder="Réference"
                      isInvalid={errors.reference && touched.reference}
                      value={values.reference}
                      onChange={handleChange('reference')}
                    />
                    {errors.reference && touched.reference && (
                      <FormErrorMessage>{errors.reference}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.quantities && touched.quantities}
                  >
                    <FormLabel>Quantité</FormLabel>
                    <Input
                      placeholder="Quantité"
                      isInvalid={errors.quantities && touched.quantities}
                      value={values.quantities}
                      onChange={handleChange('quantities')}
                    />
                    {errors.quantities && touched.quantities && (
                      <FormErrorMessage>{errors.quantities}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.quantities && touched.quantities}
                  >
                    <FormLabel>Uploader Ficher</FormLabel>
                    <input
                      type="file"
                      isInvalid={errors.file && touched.file}
                      onChange={e => {
                        handleFileChange(e, setFieldValue);
                      }}
                    />
                    {errors.file && touched.file && (
                      <FormErrorMessage>{errors.file}</FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                      resetForm();
                    }}
                  >
                    Fermer
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}
function PreviewPdfModal({ children, url }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
  
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    return (
      <>
        <Box onClick={onOpen}>{children}</Box>
        <Modal
          isCentered
          onClose={onClose}
          isOpen={isOpen}
          motionPreset="slideInBottom"
          size={'full'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <iframe
                  src={`${envConfig().apiBaseUrl}/storage/${url}`}
                  width="100%"
                  height="500px"
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
export default AdminDocumentPage;
