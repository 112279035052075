import React, { useState, useEffect } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useDisclosure,
  FormControl,
  Input,
  FormLabel,
  Flex,
  Box,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  Select as ChakraSelect,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { BsPencilSquare } from 'react-icons/bs';
import DeleteBtn from '../components/DeleteBtn';
import TpModal from '../components/TpModal';
import RoleForm from '../components/Form/RoleForm';
import useSWR, { mutate } from 'swr';
import Select from 'react-select';
import { MdAdd } from 'react-icons/md';
import apiRoute from '../api/route';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axiosClient, { fetcher } from '../api/api';
import Pagination from '../components/Pagination';
const AdminManageUser = () => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isLoading, mutate } = useSWR(
    `${apiRoute().users_paginate}?per_page=${perPage}&page=${currentPage}`,
    fetcher
  );
  const roles = useSWR(apiRoute().roles, fetcher);
  const [isLoadingOn, setILoadingOn] = useState(false);
  return isLoading ? (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  ) : roles.isLoading ? (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  ) : (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr bg={'white'}>
              <Th p={7}>Nom & Prenom</Th>
              <Th>Email</Th>
              <Th>Roles</Th>
              <Th>Crée le</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data?.data.map(row => (
              <Tr key={row.id} bg={'white'}>
                <Td>
                  {row.first_name} {row.last_name}
                </Td>
                <Td>{row.email}</Td>
                <Td>
                  <Flex flexWrap={'wrap'} direction={'row'} gap={3}>
                    {row.roles.map(el => (
                      <Tag size="lg" colorScheme="blue" borderRadius="full">
                        <TagLabel>{el.name}</TagLabel>
                      </Tag>
                    ))}
                  </Flex>
                </Td>
                <Td>{row.created_at}</Td>
                <Td>
                  <HStack spacing="lg" gap={3}>
                    <UserModal
                      currentPage={currentPage}
                      perPage={perPage}
                      title={'Modifier'}
                      url={`${apiRoute().users}/${row.id}`}
                      roles={roles.data.data}
                      own_roles={row.roles}
                    >
                      <IconButton
                        colorScheme="blue"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<BsPencilSquare />}
                      />
                    </UserModal>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {data.data?.total > perPage && (
        <Pagination
          currentPage={data.data?.current_page}
          totalPages={data.data?.last_page}
          onPageChange={page => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  );
};
function UserModal({
  children,
  title,
  url,
  roles = [],
  own_roles = null,
  currentPage,
  perPage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const [selectedRoles, setselectedRoles] = React.useState();
  const validateSchema = Yup.object().shape({
    role_required: Yup.string().required('Ce champs est requis'),
  });
  const options = roles.map(e => ({ label: e.name, value: e.id }));

  useEffect(() => {
    if (own_roles) {
      setDefaultValue(own_roles.map(el => ({ value: el.id, label: el.name })));
      setselectedRoles(own_roles.map(el => ({ value: el.id, label: el.name })));
    }
  }, [own_roles]);
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          role_required:
            own_roles == null || own_roles.length == 0 ? '' : 'valid',
        }}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          axiosClient
            .post(url, {
              roles: selectedRoles.map(el => el.value),
            })
            .then(res => {
              setIsLoading(false);
              mutate(apiRoute().users);
              mutate(
                `${
                  apiRoute().users_paginate
                }?per_page=${perPage}&page=${currentPage}`
              );
              setDefaultValue(selectedRoles);
              resetForm({
                values: {
                  role_required: '',
                },
              });
              onClose();
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl
                    isRequired
                    isInvalid={errors.role_required != ''}
                  >
                    <FormLabel>Roles</FormLabel>

                    <Select
                      placeholder="Choisir Roles"
                      defaultValue={defaultValue}
                      onChange={option => {
                        setselectedRoles(option);
                        if (option.length > 0) {
                          setFieldValue('role_required', 'valid', true);
                        } else {
                          setFieldValue('role_required', '', true);
                        }
                      }}
                      isMulti
                      options={options}
                    ></Select>
                    {errors.role_required != '' && (
                      <FormErrorMessage>
                        {errors.role_required}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Fermer
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default AdminManageUser;
