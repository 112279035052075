import React, { useState, useEffect } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  Input,
  FormLabel,
  Spinner,
  Button,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormErrorMessage,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { BsPencilSquare } from 'react-icons/bs';
import DeleteBtn from '../components/DeleteBtn';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { BiTrash } from 'react-icons/bi';
import useSWR, { mutate } from 'swr';
import axiosClient, { fetcher } from '../api/api';
import { Formik, Form } from 'formik';
import apiRoute from '../api/route';
import Pagination from '../components/Pagination';

function AdminTpe() {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isLoading, mutate } = useSWR(
    `${apiRoute().products_paginate}?per_page=${perPage}&page=${currentPage}`,
    fetcher
  );
  const models = useSWR(apiRoute().product_types, fetcher);
  const documents = useSWR(apiRoute().documents, fetcher);
  return !isLoading && !models.isLoading && !documents.isLoading ? (
    <>
      <Box mb={4}>
        <LinkModal
          url={apiRoute().products}
          models={models.data.data}
          documents={documents.data.data}
          currentPage={currentPage}
          perPage={perPage}
        >
          <Button rightIcon={<MdAdd />} colorScheme="teal" variant="solid">
            Nouveau
          </Button>
        </LinkModal>
      </Box>
      <TableContainer>
        <Table>
          <Thead>
            <Tr bg={'white'}>
              <Th p={7}>Nom</Th>
              <Th>Serial Number</Th>
              <Th>Type </Th>
              <Th>Crée le</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data.data.map(row => (
              <Tr key={row.id} bg={'white'}>
                <Td>{row.name}</Td>
                <Td>{row.meta.find(e => e.meta_key === 'sn').meta_value}</Td>
                <Td>{row.product_type.name}</Td>
                <Td>{row.created_at}</Td>
                <Td>
                  <HStack spacing="lg" gap={3}>
                    <DeleteBtn
                      onAction={setIsLoading => {
                        setIsLoading(true);
                        axiosClient
                          .delete(`${apiRoute().products}/${row.id}`)
                          .then(res => {
                            mutate();
                            setIsLoading(false);
                          });
                      }}
                    >
                      <IconButton
                        colorScheme="red"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<BiTrash />}
                      />
                    </DeleteBtn>
                    <LinkModal
                      url={`${apiRoute().products}/${row.id}`}
                      title={'Modifier'}
                      name={row.name}
                      type={row.product_type_id}
                      models={models.data.data}
                      documents={documents.data.data}
                      document_id={row.document_id}
                      imei={
                        row.meta.find(e => e.meta_key === 'imei').meta_value
                      }
                      sn={row.meta.find(e => e.meta_key === 'sn').meta_value}
                      currentPage={currentPage}
                      perPage={perPage}
                    >
                      <IconButton
                        colorScheme="blue"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<BsPencilSquare />}
                      />
                    </LinkModal>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {data.data?.total > perPage && (
        <Pagination
          currentPage={data.data?.current_page}
          totalPages={data.data?.last_page}
          onPageChange={page => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  ) : (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  );
}

function LinkModal({
  children,
  title,
  name = '',
  sn = '',
  type = '',
  imei = '',
  url,
  models = null,
  documents = null,
  document_id = '',
  currentPage,
  perPage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const validateSchema = Yup.object().shape({
    name: Yup.string().required('Ce champs est requis'),
    sn: Yup.string().required('Ce champs est requis'),
    type: Yup.string().required('Ce champs est requis'),
    document_id: Yup.string().required('Ce champs est requis'),
    imei: Yup.string().required('Ce champs est requis'),
  });

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          name: name,
          imei: imei,
          sn: sn,
          type: type,
          document_id: document_id,
        }}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          axiosClient
            .post(url, {
              name: values.name,
              product_type_id: values.type,
              document_id: values.document_id,
              meta: JSON.stringify([
                { meta_key: 'imei', meta_value: values.imei },
                { meta_key: 'sn', meta_value: values.sn },
              ]),
              transaction_meta: JSON.stringify([
                { meta_key: 'validators', meta_value: [] },
              ]),
            })
            .then(res => {
              setIsLoading(false);
              mutate(
                `${
                  apiRoute().products_paginate
                }?per_page=${perPage}&page=${currentPage}`
              );
              if (name === '') {
                resetForm({
                  values: {
                    name: '',
                  },
                });
              }

              onClose();
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl
                    isRequired
                    isInvalid={errors.name && touched.name}
                  >
                    <FormLabel>Nom</FormLabel>
                    <Input
                      placeholder="Nom du tpe"
                      isInvalid={errors.name && touched.name}
                      value={values.name}
                      onChange={handleChange('name')}
                    />
                    {errors.name && touched.name && (
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isRequired isInvalid={errors.sn && touched.sn}>
                    <FormLabel>SN</FormLabel>
                    <Input
                      placeholder="Serial number"
                      isInvalid={errors.sn && touched.sn}
                      value={values.sn}
                      onChange={handleChange('sn')}
                    />
                    {errors.sn && touched.sn && (
                      <FormErrorMessage>{errors.sn}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.imei && touched.imei}
                  >
                    <FormLabel>Imei</FormLabel>
                    <Textarea
                      placeholder="Imei 1, Imei 2"
                      isInvalid={errors.imei && touched.imei}
                      value={values.imei}
                      onChange={handleChange('imei')}
                    />
                    {errors.imei && touched.imei && (
                      <FormErrorMessage>{errors.imei}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.type && touched.type}
                  >
                    <FormLabel>Type de tpe</FormLabel>
                    <Select
                      placeholder="Type de tpe"
                      value={values.type}
                      onChange={handleChange('type')}
                    >
                      {models.map(e => (
                        <option value={e.id}>{e.name}</option>
                      ))}
                    </Select>
                    {errors.type && touched.type && (
                      <FormErrorMessage>{errors.type}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.document_id && touched.document_id}
                  >
                    <FormLabel>Type de tpe</FormLabel>
                    <Select
                      placeholder="Reference Document"
                      value={values.document_id}
                      onChange={handleChange('document_id')}
                    >
                      {documents.map(e => (
                        <option value={e.id}>{e.reference}</option>
                      ))}
                    </Select>
                    {errors.document_id && touched.document_id && (
                      <FormErrorMessage>{errors.document_id}</FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Fermer
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AdminTpe;
