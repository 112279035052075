import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { extractLinkAndFunctions, compareElements } from '../utils/helpers';
import { LINKS_ITEMS } from '../constant/app_constant';
import NotFoundPage from '../pages/NotFoundPage';
const PrivateRoute = ({ element }) => {
  const user = useSelector(state => state.user.user);
  const location = useLocation();
  let navItem = compareElements(
    extractLinkAndFunctions(user),
    LINKS_ITEMS
  ).find(el => el.url === location.pathname);
  navItem = true;
  // return  user.token != null ? element : <Navigate to="/login" />;
  return user.token != null ? (
    navItem != null ? (
      element
    ) : (
      <NotFoundPage />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
