import React, { useEffect, useState } from 'react';
import {
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useDisclosure,
  FormControl,
  Input,
  FormLabel,
  Flex,
  Box,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  Select as ChakraSelect,
} from '@chakra-ui/react';
import Select from 'react-select';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { BsPencilSquare } from 'react-icons/bs';
import DeleteBtn from '../components/DeleteBtn';
import TpModal from '../components/TpModal';
import PermissionForm from '../components/Form/PermissionForm';
import useSWR, { mutate } from 'swr';
import { MdAdd } from 'react-icons/md';
import apiRoute from '../api/route';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axiosClient, { fetcher } from '../api/api';
import Pagination from '../components/Pagination';
function AdminPermission() {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isLoading, mutate } = useSWR(
    `${
      apiRoute().permissions_paginate
    }?per_page=${perPage}&page=${currentPage}`,
    fetcher
  );
  const links = useSWR(apiRoute().links, fetcher);
  const [isLoadingOn, setILoadingOn] = useState(false);

  return isLoading ? (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  ) : links.isLoading ? (
    <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  ) : (
    <>
      <Box mb={4}>
        <PermissonModal
          url={apiRoute().permissions}
          links={links.data}
          currentPage={currentPage}
          perPage={perPage}
        >
          <Button rightIcon={<MdAdd />} colorScheme="teal" variant="solid">
            Nouveau
          </Button>
        </PermissonModal>
      </Box>
      <TableContainer>
        <Table>
          <Thead>
            <Tr bg={'white'}>
              <Th p={7}>Nom</Th>
              <Th>Route</Th>
              <Th>Crée le</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data.data.map(row => (
              <Tr key={row.id} bg={'white'}>
                <Td>{row.name}</Td>
                <Td>{row.link?.name}</Td>
                <Td>{row.created_at}</Td>
                <Td>
                  <HStack spacing="lg" gap={3}>
                    <DeleteBtn
                      onAction={( setIsLoading) => {
                        setIsLoading(true)
                        axiosClient
                          .delete(`${apiRoute().permissions}/${row.id}`)
                          .then(res => {
                            setIsLoading(false);
                            mutate();
                          });
                      }}
                    >
                      <IconButton
                        colorScheme="red"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<BiTrash />}
                      />
                    </DeleteBtn>
                    <PermissonModal
                      title={'Modifier'}
                      name={row.name}
                      links={links.data}
                      link_id={row.link_id}
                      action={row.function}
                      url={`${apiRoute().permissions}/${row.id}`}
                      currentPage={currentPage}
                      perPage={perPage}
                    >
                      <IconButton
                        colorScheme="blue"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<BsPencilSquare />}
                      />
                    </PermissonModal>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {data.data?.total > perPage && (
        <Pagination
          currentPage={data.data?.current_page}
          totalPages={data.data?.last_page}
          onPageChange={page => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  );
}

function PermissonModal({
  children,
  title,
  links,
  name = '',
  link_id = '',
  url,
  action = null,
  currentPage,
  perPage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(
    action == null
      ? { value: 'create', label: 'Créer' }
      : typeof action == 'object'
      ? action
      : action
  );
  const [selectedOption, setSelectedOption] = React.useState({
    value: 'create',
    label: 'Créer',
  });
  const validateSchema = Yup.object().shape({
    name: Yup.string().required('Ce champs est requis'),
    link_id: Yup.number().required('Ce champs est requis'),
    function: Yup.string().required('Ce champs est requis'),
  });
  const options = [
    { value: 'create', label: 'Créer' },
    { value: 'update', label: 'Modifier' },
    { value: 'delete', label: 'supprimer' },
    { value: 'list', label: 'Afficher' },
  ];
  useEffect(() => {
    setDefaultValue(
      action == null
        ? { value: 'create', label: 'Créer' }
        : typeof action == 'object'
        ? action
        : action
    );
  }, [action]);
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Formik
        initialValues={{
          name: name,
          link_id: link_id,
          function: 'valid',
        }}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          axiosClient
            .post(url, {
              link_id: parseInt(values.link_id),
              function: JSON.stringify(selectedOption),
              name: values.name,
            })
            .then(res => {
              setIsLoading(false);
              mutate(
                `${
                  apiRoute().permissions_paginate
                }?per_page=${perPage}&page=${currentPage}`
              );
              if (name == '') {
                setDefaultValue(selectedOption);
                resetForm({
                  values: {
                    name: '',
                    link_id: '',
                    function: 'valid',
                  },
                });
              }

              onClose();
            });
        }}
        validationSchema={validateSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form>
            <Modal
              isCentered
              onClose={onClose}
              isOpen={isOpen}
              motionPreset="slideInBottom"
              size={'xl'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl
                    isRequired
                    isInvalid={errors.name && touched.name}
                  >
                    <FormLabel>Nom</FormLabel>
                    <Input
                      placeholder="Ajouter une permission"
                      isInvalid={errors.name && touched.name}
                      value={values.name}
                      onChange={handleChange('name')}
                    />
                    {errors.name && touched.name && (
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={errors.link_id && touched.link_id}
                  >
                    <FormLabel>Lien</FormLabel>

                    <ChakraSelect
                      placeholder="Choisir le lien"
                      isInvalid={errors.link_id && touched.link_id}
                      value={values.link_id}
                      onChange={handleChange('link_id')}
                    >
                      {links.data.map(link => (
                        <option value={link.id}>{link.name}</option>
                      ))}
                    </ChakraSelect>
                    {errors.link_id && touched.link_id && (
                      <FormErrorMessage>{errors.link_id}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isRequired isInvalid={errors.function != ''}>
                    <FormLabel>Action</FormLabel>

                    <Select
                      placeholder="Choisir actions"
                      defaultValue={defaultValue}
                      onChange={option => {
                        setSelectedOption(option);
                        if (option.length > 0) {
                          setFieldValue('function', 'valid', true);
                        } else {
                          setFieldValue('function', '', true);
                        }
                      }}
                      isMulti
                      options={options}
                    ></Select>
                    {errors.function != '' && (
                      <FormErrorMessage>{errors.function}</FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Fermer
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  >
                    Valider
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AdminPermission;
