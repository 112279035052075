import React from 'react';
import { Container, Text, SimpleGrid, Box } from '@chakra-ui/react';
import useSWR, { mutate } from 'swr';
import axiosClient, { fetcher } from '../api/api';
import { Formik, Form } from 'formik';
import apiRoute from '../api/route';
function AdminHomePage() {
  const statData = [
    {
      id: 1,
      label: 'Total post reactions',
      score: '1,730',
    },
    {
      id: 2,
      label: 'Total post views',
      score: '31,573',
    },
    {
      id: 3,
      label: 'Listings created',
      score: '5',
    },
  ];
  const stats = useSWR(`${apiRoute().stats}`, fetcher,{refreshInterval:10000});
  return (
    
    <Container maxW="7xl" p={{ base: 5, md: 10 }}>
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3 }}
        spacing={5}
        mb={4}
      
      >
        {stats.data?.data?.map(data => (
          <Box key={data.id} p={5} boxShadow="md" rounded="md" borderWidth={1}   backgroundColor={"white"}>
            <Text fontWeight="extrabold" fontSize="x-large">
              {data.value}
            </Text>
            <Text>{data.label}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  );
}

export default AdminHomePage;
