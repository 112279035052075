
import React from 'react';
import {
   Box,
   Heading,
   Container,
   Text,
   Button,
   Stack,
   SimpleGrid,
   Icon,
   HStack,
   VStack,
   useColorModeValue,
   useBreakpointValue,
   Flex
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import {
   FcAbout,
   FcAssistant,
   FcCollaboration,
   FcDonate,
   FcManager,
 } from 'react-icons/fc'
function HomePage() {
   return (<>
      <WithBackgroundImage />
      <GridListWith/>
   </>)
}

const features = Array.apply(null, Array(8)).map(function (x, i) {
   return {
      id: i,
      title: 'Lorem ipsum dolor sit amet',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',
   }
})

function Feature() {
   return (
      <Box p={4} mb={10}>
         <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
            <Heading fontSize={'3xl'}>This is the headline</Heading>
            <Text color={'gray.600'} fontSize={'xl'}>
               Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
               tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
            </Text>
         </Stack>

         <Container maxW={'6xl'} mt={10}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
               {features.map((feature) => (
                  <HStack key={feature.id} align={'top'}>
                     <Box color={'green.400'} px={2}>
                        <Icon as={CheckIcon} />
                     </Box>
                     <VStack align={'start'}>
                        <Text fontWeight={600}>{feature.title}</Text>
                        <Text color={'gray.600'}>{feature.text}</Text>
                     </VStack>
                  </HStack>
               ))}
            </SimpleGrid>
         </Container>
      </Box>
   )
}

function WithBackgroundImage() {
   return (
      <Flex
         w={'full'}
         h={'45vh'}
         backgroundImage={
            'url(https://images.unsplash.com/photo-1600267175161-cfaa711b4a81?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)'
         }
         backgroundSize={'cover'}
         backgroundPosition={'center center'}
         mb={10}
         >
         <VStack
            w={'full'}
            justify={'center'}
            px={useBreakpointValue({ base: 4, md: 8 })}
            bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
            <Container maxW={'3xl'}>
               <Stack
                  as={Box}
                  textAlign={'center'}
                  spacing={{ base: 8, md: 14 }}
                  py={{ base: 20, md: 36 }}>
                  <Heading
                     color={'white'}
                     fontWeight={600}
                     fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                     lineHeight={'110%'}>
                     Make money from <br />
                     <Text as={'span'} color={'blue.500'}>
                        your audience
                     </Text>
                  </Heading>
                  <Text color={'white'}>
                     Monetize your content by charging your most loyal readers and reward them
                     loyalty points. Give back to your loyal readers by granting them access to
                     your pre-releases and sneak-peaks.
                  </Text>
                  <Stack
                     direction={'column'}
                     spacing={3}
                     align={'center'}
                     alignSelf={'center'}
                     position={'relative'}>
                     <Button
                        colorScheme={'blue'}
                        bg={'blue.500'}
                        rounded={'full'}
                        px={6}
                        _hover={{
                           bg: 'blue.600',
                        }}>
                        Get Started
                     </Button>
                     <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                        Learn more
                     </Button>
                  </Stack>
               </Stack>
            </Container>
         </VStack>
      </Flex>
   )
}
const Card = ({ heading, description, icon, href }) => {
   return (
     <Box
       maxW={{ base: 'full', md: '275px' }}
       w={'full'}
       borderWidth="1px"
       borderRadius="lg"
       overflow="hidden"
       p={5}>
       <Stack align={'start'} spacing={2}>
         <Flex
           w={16}
           h={16}
           align={'center'}
           justify={'center'}
           color={'white'}
           rounded={'full'}
           bg={useColorModeValue('gray.100', 'gray.700')}>
           {icon}
         </Flex>
         <Box mt={2}>
           <Heading size="md">{heading}</Heading>
           <Text mt={1} fontSize={'sm'}>
             {description}
           </Text>
         </Box>
         <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
           Learn more
         </Button>
       </Stack>
     </Box>
   )
 }
 
function GridListWith() {
   return (
     <Box p={4} mb={16}>
       <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
         <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
           Short heading
         </Heading>
         <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
           Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis obcaecati ut
           cupiditate pariatur, dignissimos, placeat amet officiis.
         </Text>
       </Stack>
 
       <Container maxW={'5xl'} mt={12}>
         <Flex flexWrap="wrap" gridGap={6} justify="center">
         
           <Card
             heading={'Heading'}
             icon={<Icon as={FcManager} w={10} h={10} />}
             description={'Lorem ipsum dolor sit amet catetur, adipisicing elit.'}
             href={'#'}
           />
           <Card
             heading={'Heading'}
             icon={<Icon as={FcAbout} w={10} h={10} />}
             description={'Lorem ipsum dolor sit amet catetur, adipisicing elit.'}
             href={'#'}
           />
         </Flex>
       </Container>
     </Box>
   )
 }
export default HomePage;