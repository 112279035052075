import React, { useState, useEffect } from 'react'
import {
    IconButton, HStack, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,
    Flex,
    Box,
    Spinner,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormErrorMessage,
    Select as ChakraSelect,
    Tag,
    TagLabel
} from '@chakra-ui/react'
import { BiEdit, BiTrash } from "react-icons/bi";
import { BsPencilSquare } from "react-icons/bs";
import DeleteBtn from '../components/DeleteBtn';
import TpModal from '../components/TpModal';
import RoleForm from '../components/Form/RoleForm';
import useSWR, { mutate } from 'swr';
import Select from 'react-select';
import { MdAdd } from "react-icons/md";
import apiRoute from '../api/route';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import axiosClient, { fetcher } from '../api/api';
export default function AdminRole() {
    const { data, error, isLoading, mutate } = useSWR(apiRoute().roles, fetcher)
    const permissions = useSWR(apiRoute().permissions, fetcher)
    const [isLoadingOn, setILoadingOn] = useState(false);

    return isLoading ?
        <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'} >

            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
        </Flex> : permissions.isLoading ?
            <Flex alignItems={'center'} justifyContent={'center'} minH={'70vh'} >
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Flex> : (
                <>
                    <Box mb={4}>
                        <RoleModal url={apiRoute().roles} permissions={permissions.data.data}>
                            <Button
                                rightIcon={<MdAdd />}
                                colorScheme='teal' variant='solid'>
                                Nouveau
                            </Button>
                        </RoleModal>
                    </Box>
                    <TableContainer>
                        <Table >
                            <Thead>
                                <Tr bg={'white'}>
                                    <Th p={7}>Nom</Th>
                                    <Th>Permission</Th>
                                    <Th>Crée le</Th>
                                    <Th>Action</Th>

                                </Tr>
                            </Thead>
                            <Tbody>
                                {data.data.map((row) => (
                                    <Tr key={row.id} bg={'white'}>
                                        <Td>{row.name}</Td>
                                        <Td>
                                            <Flex flexWrap={'wrap'} direction={'row'} gap={3}>
                                                {row.permissions.map((el) =>
                                                (<Tag size='lg' colorScheme='blue' borderRadius='full'>
                                                    <TagLabel>{el.name}</TagLabel>
                                                </Tag>)
                                                )}

                                            </Flex>
                                        </Td>
                                        <Td>{row.created_at}</Td>
                                        <Td>
                                            <HStack spacing='lg' gap={3}>
                                                <DeleteBtn isLoading={isLoadingOn}
                                                    onAction={() => {
                                                        setILoadingOn(true)
                                                        axiosClient.delete(`${apiRoute().roles}/${row.id}`).then((res) => {
                                                            setILoadingOn(false)
                                                            mutate()
                                                        })
                                                    }}>
                                                    <IconButton
                                                        colorScheme='red'
                                                        aria-label='Call Segun'
                                                        size='lg'
                                                        icon={<BiTrash />}
                                                    />
                                                </DeleteBtn>
                                                <RoleModal title={'Modifier'} permissions={permissions.data.data} url={`${apiRoute().roles}/${row.id}`} name={row.name} own_permissions={row.permissions}

                                                >
                                                    <IconButton
                                                        colorScheme='blue'
                                                        aria-label='Call Segun'
                                                        size='lg'
                                                        icon={<BsPencilSquare />}
                                                    />
                                                </RoleModal>

                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>

                        </Table>
                    </TableContainer>
                </>
            )
}


function RoleModal({ children, title, url, permissions = null, name = '', own_permissions = null }) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState();
    const [selectedPermissions, setSelectedPermissions] = React.useState();
    const validateSchema = Yup.object().shape({
        name: Yup.string().required("Ce champs est requis"),
        permission_required: Yup.string().required("Ce champs est requis")
    });
    const options = permissions.map((e) => ({ label: e.name, value: e.id }));
    useEffect(() => {
        if (own_permissions) {
            setDefaultValue(own_permissions.map((el) => ({ value: el.id, label: el.name })))
            setSelectedPermissions(own_permissions.map((el) => ({ value: el.id, label: el.name })))
        }
    }, [own_permissions])
    return (
        <>
            <Box onClick={onOpen}>{children}</Box>
            <Formik initialValues={{
                name: name,
                permission_required: own_permissions == null ? '' : 'valid'
            }}

                onSubmit={(values, { resetForm }) => {
                   
                    setIsLoading(true)
                    axiosClient.post(url, {
                        name: values.name,
                        permissions: selectedPermissions.map((el) => el.value)
                    }).then((res) => {
                        setIsLoading(false)
                        mutate(apiRoute().roles)
                        if (name == '') {
                            setDefaultValue(selectedPermissions)
                            resetForm({
                                values: {
                                    name: '',
                                    permission_required: ''
                                }
                            })
                        }

                        onClose()
                    })
                }}
                validationSchema={validateSchema}
            >
                {({ handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    touched,
                    errors,
                    setFieldValue }) => (
                    <Form>
                        <Modal
                            isCentered
                            onClose={onClose}
                            isOpen={isOpen}
                            motionPreset='slideInBottom'
                            size={'xl'}
                        >
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>{title}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl isRequired isInvalid={errors.name && touched.name}>
                                        <FormLabel>Nom</FormLabel>
                                        <Input placeholder='Ajouter un role'
                                            isInvalid={errors.name && touched.name}
                                            value={values.name}
                                            onChange={handleChange('name')} />
                                        {errors.name && touched.name && (
                                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                                        )}
                                    </FormControl>

                                    <FormControl isRequired isInvalid={errors.permission_required != ''}>
                                        <FormLabel>Permission</FormLabel>

                                        <Select placeholder='Choisir permission'
                                            defaultValue={defaultValue}
                                            onChange={(option) => {
                                                setSelectedPermissions(option)
                                                if (option.length > 0) {
                                                    setFieldValue('permission_required', 'valid', true)
                                                } else {
                                                    setFieldValue('permission_required', '', true)
                                                }

                                            }}
                                            isMulti
                                            options={options}
                                        >
                                        </Select>
                                        {errors.permission_required != '' && (
                                            <FormErrorMessage>{errors.permission_required}</FormErrorMessage>
                                        )}
                                    </FormControl>
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Fermer
                                    </Button>
                                    <Button variant='ghost' onClick={handleSubmit} isLoading={isLoading}>Valider</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </>
    );
}