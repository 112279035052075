import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const NotFoundPage = () => {
  return (
    <Box textAlign="center" mt="20">
      <Heading size="xl" color="red.500">
        404 - Page not found
      </Heading>
      <p>Sorry, the page you are looking for does not exist.</p>
    </Box>
  );
};

export default NotFoundPage;
